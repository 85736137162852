// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newChatBackground {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0.82;
    background-color: #000000;
    width: 100vw;
    height: 100vh;
    z-index: 9997;
}

.newChatDialogBox {
    position: fixed;
    z-index: 9998;
    transform: translate(-50%, 0%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
    height: 100vh;
    width: 100vw;
    -webkit-backdrop-filter: blur(1.5px);
            backdrop-filter: blur(1.5px);
}

.newChatTitle {
    align-self: center;
    text-align: center;
    font-size: 1.55rem;
    line-height: 2rem;
    color: #FFFFFF;
    padding: 2.5% 10%;
}

.newChatAction {
    display: flex;
    justify-content: center;
    padding: 2.5%;
    gap: 3rem;
}

.newChatBtn {
    display: flex;
    text-align: center;
    background: transparent;
    padding: .8rem 2rem;
    cursor: pointer;
    border: 3px solid #FFFFFF;
    border-radius: 1.15rem;
    gap: 0.9rem;
}

.closeBtn {
    color: #FFFFFF;
}

.startBtn {
    color: #4E59E3;
    background: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/core/Components/NewChatDialog/NewChatDialog.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,oCAA4B;YAA5B,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".newChatBackground {\n    position: fixed;\n    top: 0;\n    left: 0;\n    opacity: 0.82;\n    background-color: #000000;\n    width: 100vw;\n    height: 100vh;\n    z-index: 9997;\n}\n\n.newChatDialogBox {\n    position: fixed;\n    z-index: 9998;\n    transform: translate(-50%, 0%);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 10%;\n    height: 100vh;\n    width: 100vw;\n    backdrop-filter: blur(1.5px);\n}\n\n.newChatTitle {\n    align-self: center;\n    text-align: center;\n    font-size: 1.55rem;\n    line-height: 2rem;\n    color: #FFFFFF;\n    padding: 2.5% 10%;\n}\n\n.newChatAction {\n    display: flex;\n    justify-content: center;\n    padding: 2.5%;\n    gap: 3rem;\n}\n\n.newChatBtn {\n    display: flex;\n    text-align: center;\n    background: transparent;\n    padding: .8rem 2rem;\n    cursor: pointer;\n    border: 3px solid #FFFFFF;\n    border-radius: 1.15rem;\n    gap: 0.9rem;\n}\n\n.closeBtn {\n    color: #FFFFFF;\n}\n\n.startBtn {\n    color: #4E59E3;\n    background: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
