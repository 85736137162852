import * as React from 'react';
import "./ChatInputBar.css";
import { INPUT_PLACEHOLDER } from '../../constants/Placeholder-Constants';

const ChatInputBar = ({ selectedLanguageCode, input, disableFlag = false, send = () => { } }) => {
  const textareaRef = React.useRef(null);
  const [inputText, setInputText] = React.useState('');

  React.useEffect(() => {
    // if(inputText.length > 0) {
    //   setInputText(inputText + " " + input);
    // }
    // else {
      setInputText(input);
    // }
  },[input]);

  function sendText() {
    send(inputText);
    setInputText('');
    adjustTextareaHeight(true);
  }

  const adjustTextareaHeight = (resetFlag) => {
    const textarea = textareaRef.current;
    if (textarea) {
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10);
      const maxRows = resetFlag ? 1.8 : 5;
      const newHeight = Math.min(textarea.scrollHeight, maxRows * lineHeight);
      textarea.style.height = `${newHeight}px`;
    }
  };

  return (
    <div className='relative bg-[#FFFFFF] rounded-xl lg:w-[75vw] w-[80vw] flex items-center justify-start' style={{ border: "1px solid #4e59e378" }}>
      <textarea
        ref={textareaRef}
        rows="1"
        placeholder={INPUT_PLACEHOLDER[selectedLanguageCode]}
        className='chat-input lg:text-xl md:text-lg sm:text-md lg:p-3 p-2 rounded-xl md:w-[97%] w-[90%] outline-none resize-none placeholder:italic placeholder:text-[15px]'
        value={inputText}
        onChange={(event) => { setInputText(event.target.value);
          event.target.value.length > 0 ? adjustTextareaHeight() : adjustTextareaHeight(true); }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            sendText();
          }
        }}
        disabled={disableFlag}
      />
      <div className={`flex items-center justify-center absolute md:right-[1rem] right-[0.8rem] top-[50%] ${inputText ? "cursor-pointer" : "pointer-events-none"}`} style={{ transform: "translateY(-50%)" }} onClick={sendText}>
        <img src="/Assets/Images/send.svg" />
      </div>
    </div>
  );
}

export default ChatInputBar;
