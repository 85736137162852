import * as React from "react";
import "./Feedback.css";
import FeedbackDialog from "../FeedbackDialog/FeedbackDialog";
import {feedbackAPICall} from "../../../js/global";

const Feedback = ({selectedLangaugeCode, qid, sessionId, token, stopAudio = () => { }}) => {
  const [feedback, setFeedback] = React.useState({ key: "", values: [] });
  const [feedbackDialog, setFeedbackDialog] = React.useState(false);
  const session_id = React.useState(sessionId);

  const handleFeedbackChange = (value) => {
    stopAudio();
    if (feedback["key"] !== value) {
      setFeedback({ key: value, values: [] });
      if (value !== "like") {
        setFeedbackDialog(true);
      } else {
        feedbackAPICallback({ key: "like", values: [] });
      }
    } else {
      setFeedback({ key: "", values: [] });
      feedbackAPICallback({ key: "", values: [] });
    }
  };

  const handleFeedbackDialogChange = (inpvalues) => {
    console.log(inpvalues);
    setFeedback((prevVal) => {
      const updatedValues = prevVal.values.includes(inpvalues)
          ? prevVal.values.filter(value => value !== inpvalues)
          : [...prevVal.values, inpvalues];

      return { ...prevVal, values: updatedValues };
  });
  };

  const feedbackAPICallback = React.useCallback(
    (inpFeedback, toggleFeedbackDialog = () => {}) => {
      feedbackAPICall(token, qid, inpFeedback);
      toggleFeedbackDialog((prevClosedDialog) => !prevClosedDialog);
    },
    [feedback, qid, session_id]
  );

  const toggleFeedbackDialogBox = React.useCallback(() => {
    feedbackAPICallback(feedback, setFeedbackDialog);
  }, [feedbackAPICallback]);

  return (
    <div className="feedback_container">
      {feedback["key"] ? (
        <>
          <img
            src={feedback["key"] === "like" ? "/Assets/Images/like_feed_icn.svg" : "/Assets/Images/like_icn.png"}
            className="like_icn"
            alt="Asha Avatar"
            onClick={() => handleFeedbackChange("like")}
          />
          <img
            src={feedback["key"] === "dislike" ? "/Assets/Images/dislike_feed_icn.svg" : "/Assets/Images/dislike_icn.svg"}
            className="dislike_sel_icn"
            alt="Asha Avatar"
            onClick={() => handleFeedbackChange("dislike")}
          />
        </>
      ) : (
        <>
          <img
            src={"/Assets/Images/like_icn.png"}
            className="like_icn"
            alt="Asha Like"
            onClick={() => handleFeedbackChange("like")}
          />
          <img
            src={"/Assets/Images/like_icn.png"}
            className="dislike_icn"
            alt="Asha Dislike"
            onClick={() => handleFeedbackChange("dislike")}
          />
        </>
      )}
      {feedbackDialog && (
        <FeedbackDialog
          selectedLangaugeCode={selectedLangaugeCode}
          toggleDialogBox={toggleFeedbackDialogBox}
          feedbackValues={feedback["values"]}
          handleFeedbackDialogChange={handleFeedbackDialogChange}
        />
      )}
    </div>
  );
};

export default Feedback;
