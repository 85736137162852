import axios from "axios";
import { languageName } from "../constants/Language-Constants";
import { createApi, chatDataApi, saveResponseApi, ashaModelApi, speechToTextApi, feedbackApi, resetApi } from "../env";
import { convertTextToSpeech } from "./AWS/aws-polly";
import fetchApi from "./fetchApi";
import { formatUserResp } from "./helper";
import { PROMPT_ERROR_MAP } from "../constants/Error-Constants";

export function getChatHistory(payloadJSON) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  return fetchApi(createApi, "POST", JSON.stringify(payloadJSON), headerValue, {})
    .then(res => {
      return res
    });
}

export function getCurrentQuestion(token) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'x-access-token': token
  }
  return fetchApi(chatDataApi, "GET", {}, headerValue, {})
    .then(res => {
      return res
    });
}

export function getAshaModelResp(token, selectedLanguageCode, inputText) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'x-access-token': token
  }
  var payloadJSON = {
    lang: selectedLanguageCode,
    query: inputText
  }
  return fetchApi(ashaModelApi, "POST", JSON.stringify(payloadJSON), headerValue, {})
    .then(res => {
      if(res["error"]) {
        return {
          "output": {
            "bot": PROMPT_ERROR_MAP
          }
        }
      }
      return res;
    });
}


export function saveResponse(token, q_id, response) {
  let payload = {
    "qid": q_id,
    "values": [response]
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'x-access-token': token
  }
  return fetchApi(saveResponseApi, "PUT", JSON.stringify(payload), headerValue, {})
    .then(res => {
      return res;
    })
}

export async function generateSpeech(text, langCode) {
  const url = await convertTextToSpeech(text, langCode);
  return url;
}

export function feedbackAPICall (token, q_id, feedback) {
  let payload = {
    "qid": q_id,
    "responses": [feedback]
  }
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'x-access-token': token
  }
  return fetchApi(feedbackApi, "PUT", JSON.stringify(payload), headerValue, {})
    .then(res => {
      return res
    });
}


export function generateSpeechText(selectedLanguageCode, audioBlob) {
  var formData = new FormData();
  formData.append("file", audioBlob, 'audio-sample.mp3');
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: speechToTextApi,
    headers: {
      "x-lang-code": languageName[selectedLanguageCode],
      "Content-Type": "audio/mpeg",
    },
    data: formData,
  };

  return axios(config)
          .then(res  => {
            return res.data.text;
          })
}

export function handleChatReset(token) {
  var headerValue = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    "x-access-token": token
  }
  return fetchApi(resetApi, "PUT", {}, headerValue, {})
    .then(res => {
      return res
    });
}