import * as React from "react";
import ChatQueueBlock from "../ChatQueueBlock/ChatQueueBlock";
import "./ChatQueue.css";
import { AUTOCOMPLETE_SYMP_QUES_VAL } from "../../../constants/Constants";

const ChatQueue = ({ stopAudioFlag, username, chatData, currentBlock, selectedLanguage, audioPlayer, setAudioPlayer, requestController, setRequestController, setAnswer = () => { }, token, isLoading}) => {
  const currQuesRef = React.useRef(null);
  const [speakerEnabled, setSpeakerEnabled] = React.useState(false);
  const audioRef = React.useRef();

  React.useEffect(() => {
    stopAudio();
  },
  [stopAudioFlag]);

  const scrollToBottom = () => {
    const chatAreaDiv = document.getElementById("chatQueue");
    if (currQuesRef?.current?.offsetTop !== chatAreaDiv.offsetTop) {
      currQuesRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const playAudio = ((url) => {
    const newAudio = new Audio(url);
    if (audioRef.current) {
      audioRef.current.pause();
    }
    if (requestController) {
      requestController.abort();
    }

    newAudio.addEventListener("ended", () => {
      setSpeakerEnabled(false);
    });

    audioRef.current = newAudio;
    newAudio.play().catch((err) => {
      console.error("Error playing audio:", err);
    });
  });

  const stopAudio = (() => {
    if(audioRef.current) {
      setSpeakerEnabled(false);
      audioRef.current.pause();
    }
  });

  React.useEffect(() => {
    return () => {
      stopAudio();
    };
  }, []);

  React.useEffect(scrollToBottom, [currentBlock]);

  const getChatItm = () => {
    return chatData.length === 0
      ? ""
      : chatData.map((itm, idx) => (
        <div
          key={idx}
          style={{ display: itm?.prompt?.text["1"] ? "block" : "none" }}
          className='chatQueue_itm'
          ref={idx === chatData.length - 1 ? currQuesRef : null}
        >
          <ChatQueueBlock
            key={idx}
            showIcon={itm.src != chatData[idx - 1]?.src}
            username={username}
            item={itm}
            idx={idx}
            audioPlayer={audioPlayer}
            setAudioPlayer={setAudioPlayer}
            requestController={requestController}
            setRequestController={setRequestController}
            speakerEnabled={speakerEnabled}
            activeState={idx == chatData.length - 1}
            setSpeakerEnabled={setSpeakerEnabled}
            selectedLanguageCode={selectedLanguage}
            setAnswer={setAnswer}
            playAudio={playAudio}
            stopAudio={stopAudio}
            token={token}
            lastQuesFlag={idx == chatData.length - 1}
            isLoading={isLoading}
          />
        </div>
      ));
  };
  return (
    <div id="chatQueue" className="chatQueue">{getChatItm()}</div>
  )
};

export default ChatQueue;
