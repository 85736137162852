import * as React from 'react';
import Header from '../Header/Header';
import { offline_title, offline_desc, offline_btn } from '../../../constants/Offline-Constant';

const OfflineScreen = ({selectedLanguageCode, handleTryAgain = () => {}}) => {
  return (
    <div className='h-full w-full flex flex-col items-center'>
        <Header selectedLanguageCode={selectedLanguageCode} offline={true}/>
        <div className='relative mt-[5rem] h-[96vh] w-[100vw] flex flex-col bg-[#F2F3F9] gap-3'>
            <div className='flex flex-col justify-center items-center md:gap-3 gap-2 h-[100%] lg:px-64 md:px-40 sm:px-16 px-4'>
                <div className='items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" width="109" height="111" viewBox="0 0 109 111" fill="none">
                    <g clip-path="url(#clip0_1670_19749)">
                        <path d="M101.847 50.1812C95.1858 43.7062 87.9002 38.5416 79.9901 34.6875C72.08 30.8333 63.5833 28.9062 54.5 28.9062C51.6993 28.9062 49.0122 29.0797 46.4385 29.4265C43.8649 29.7734 41.7076 30.2552 39.9667 30.8719L31.6781 22.4312C35.0087 21.1979 38.6231 20.2344 42.5214 19.5406C46.4196 18.8469 50.4125 18.5 54.5 18.5C65.0972 18.5 75.07 20.7354 84.4182 25.2062C93.7665 29.6771 101.96 35.5739 109 42.8969L101.847 50.1812ZM82.6583 69.7218C80.1604 67.2552 77.8896 65.2703 75.8458 63.7672C73.8021 62.264 71.1906 60.7802 68.0115 59.3156L55.1813 46.25C62.3722 46.4041 68.7116 47.9073 74.1995 50.7593C79.6873 53.6114 84.8913 57.5041 89.8115 62.4375L82.6583 69.7218ZM91.401 103.022L46.6656 57.4656C42.5781 58.4677 38.8123 60.0864 35.3682 62.3218C31.9241 64.5573 28.9153 67.0239 26.3417 69.7218L19.1885 62.4375C21.9892 59.5854 24.8846 57.0802 27.8745 54.9218C30.8644 52.7635 34.441 50.8364 38.6042 49.1406L26.001 36.3062C22.4434 38.0791 19.075 40.1797 15.8958 42.6078C12.7167 45.0359 9.80243 47.5604 7.15313 50.1812L0 42.8969C2.725 40.0448 5.63924 37.3854 8.74271 34.9187C11.8462 32.4521 15.0253 30.3323 18.2802 28.5594L7.83438 17.9219L12.7167 12.95L96.2833 98.05L91.401 103.022ZM54.5 98.3968L37.6958 81.1687C39.891 78.9333 42.4078 77.1797 45.2464 75.9078C48.0849 74.6359 51.1694 74 54.5 74C57.8306 74 60.9151 74.6359 63.7536 75.9078C66.5922 77.1797 69.109 78.9333 71.3042 81.1687L54.5 98.3968Z" fill="#4E59E3" fill-opacity="0.37"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1670_19749">
                            <rect width="109" height="111" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                </div>
                <span className='text-[#4E59E3] md:text-2xl text-xl capitalize text-center'>
                    {offline_title[selectedLanguageCode]}
                </span>
                <span className='text-[#626262] md:text-lg text-md capitalize text-center'>
                    {offline_desc[selectedLanguageCode]}
                </span>
                <div className='bg-[#4E59E3] rounded-2xl shadow-[0_2.07317px_8.29268px_0_rgba(0,0,0,0.25)] text-[#FFFFFF] w-64 text-center py-5 font-semibold cursor-pointer' onClick={handleTryAgain}>
                    {offline_btn[selectedLanguageCode]}
                </div>
            </div>
        </div>
    </div>
  )
}

export default OfflineScreen