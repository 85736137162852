export const newChat = {
    1: "New Chat",
    2: "नई चैट",
    //More languages to be added
};

export const exit = {
    1: "Exit",
    2: "बाहर जाएं",
    //More languages to be added
}