import * as React from 'react';
import { PAGES_PROMPT, REFERENCES_MSG, REFERENCES_PROMPT, SOURCES_PROMPT } from "../../../constants/Constants";
import { getTextFrmPrompt } from '../../../js/helper';

const Sources = ({item, isCollapsed, selectedLanguageCode, toggleCollapse = () => {}}) => {
  return (
    <>
    <div style={{ color: "black", display: "flex", alignItems: "center", gap: "5px", cursor: "pointer"}} onClick={toggleCollapse}>
          <img src={"/Assets/Images/sources_info_icn.png"} alt="Sources" className='sources_icon'/>
          {getTextFrmPrompt(SOURCES_PROMPT, selectedLanguageCode)}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-4 w-4 transition-transform ${isCollapsed ? 'rotate-0' : 'rotate-180'}`}
            viewBox="0 0 13 13"
            fill="none"
          >
            <path d="M3.25 4.875L6.5 8.125L9.75 4.875" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <div className={`${isCollapsed ? 'hidden' : 'block'}`}>
        <span className="text-[#5B5B5B]">{getTextFrmPrompt(REFERENCES_MSG, selectedLanguageCode)}</span>
        {item?.sources?.map((itm, idx) => {
          // console.log(itm);
          return (
            <div key={idx} className='pt-[0.75rem] text-[#0054F8] '>
              {`${idx + 1}`+ ". "}
              {getTextFrmPrompt(REFERENCES_PROMPT, selectedLanguageCode)}{` ${idx + 1}- `+" "}
              <a
                href={
                  selectedLanguageCode === "2" && itm.hi_link
                    ? itm.hi_link
                    : itm.en_link
                }
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {`${getTextFrmPrompt(PAGES_PROMPT, selectedLanguageCode)}: ${itm.page
                  } ${selectedLanguageCode === "2" && itm.hi_source
                    ? itm.hi_source
                    : itm.en_source
                  }`}
              </a>
            </div>
          );
        })}
        </div>
    </>
  );
};

export default Sources