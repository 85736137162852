export const langaugeCode = {
    "English" : "1",
    "हिंदी" : "2"
}

export const languageLabel = {
    "1" : "English",
    "2" : "हिंदी"
}

export const languageName = {
    "1": "en",
    "2": "hi"
}