import React, { useState, useRef } from 'react';
import MicRecorder from "mic-recorder-to-mp3";
import axios from 'axios';
import { generateSpeechText } from '../../js/global';

const ChatInputMic = ({ selectedLanguageCode, disableFlag, send = () => { } }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [Mp3Recorder, setMp3Recorder] = useState(
        new MicRecorder({ bitRate: 128 })
    );

    const startRecording = async () => {
        try {
            await Mp3Recorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error starting audio recording:', error);
        }
    };

    const stopRecording = () => {
        Mp3Recorder.stop()
            .getMp3()
            .then(([buffer, blob]) => {
                generateSpeechText(selectedLanguageCode, blob)
                    .then(text => {
                        send(text);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                setIsRecording(false);
            })
            .catch((e) => console.log(e));
    };

    return (
        <div className={`${disableFlag ? 'pointer-events-none' : ''}`}>
            {
                !isRecording && <div className='flex items-center justify-center md:p-3 p-2  bg-[#434cb8] rounded-xl cursor-pointer hover:bg-[#3b44a2] shadow-md' onClick={startRecording}>
                    <img src="/Assets/Images/mic.svg" className='md:h-[100%] h-[70%]' />
                </div>
            }
            {
                isRecording && <div>
                    <div className='flex items-center justify-center md:p-3 p-2  bg-[#F55014] rounded-xl cursor-pointer' onClick={stopRecording}>
                        <img src="/Assets/Images/stopIcon.svg" className='md:h-[100%] h-[70%]' />

                    </div>
                    {/* <span className='text-[#F55014]'>Tap to Stop</span> */}
                </div>
            }
        </div>
    )
}

export default ChatInputMic;