import "./FeedbackDialog.css";
import { getTextFrmPrompt } from "../../../js/helper";
import { CANCEL_PROMPT, FEEDBACK_DIALOG_MSSG, FEEDBACK_DIALOG_TITLE, FEEDBACK_PROMPT, feedbackResponses } from "../../../constants/Constants";
import UserInput from "../../../shared/UserInput/UserInput";

const FeedbackDialog = ({ selectedLangaugeCode, toggleDialogBox, feedbackValues, handleFeedbackDialogChange = () => { }}) => {

  return (
    <div className="feedbackDialog">
      <div className="feedbackDialogBackground" />
      <div className="feedbackDialogBox">
        <img
          className="feedbackDialog_cancel"
          src={"/Assets/Images/closeFormDialog.png"}
          alt={"feedbackDialog_cancel"}
          onClick={toggleDialogBox}
        />
        <p className="feedbackDialog_title">
          {getTextFrmPrompt(FEEDBACK_DIALOG_TITLE, selectedLangaugeCode)}
        </p>
        <p className="feedbackDialog_mssg">
          {getTextFrmPrompt(FEEDBACK_DIALOG_MSSG, selectedLangaugeCode)}
        </p>
        <div className="feedbackDialog_opt">
          {/* <MultiSelectChip
            {...{
              responses: feedbackResponses,
              onSave: toggleDialogBox,
              onUpdt: handleFeedbackDialogChange,
              saved: feedbackValues,
              isSaveBtnEnable: false,
            }}
          /> */}
          <UserInput
            selectedLanguageCode={selectedLangaugeCode}
            responseType={"multi-select"}
            responses={feedbackResponses}
            selectedAnswer={feedbackValues}
            activeState={true}
            setAnswer={handleFeedbackDialogChange}
          />
        </div>
        <div className="feedbackDialog_action">
          <span
            className="feedbackDialog_action_btn close_form_btn_left"
            onClick={toggleDialogBox}
          >
            {getTextFrmPrompt(FEEDBACK_PROMPT, selectedLangaugeCode)}
          </span>
          {/* <span
            className="feedbackDialog_action_btn close_form_btn_right"
            onClick={toggleDialogBox}
          >
            {getTextFrmPrompt(CANCEL_PROMPT, selectedLangaugeCode)}
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default FeedbackDialog;
