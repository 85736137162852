// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-radio {
    background: #4E59E3;
}

.custom-radio:checked {
    background: #4E59E3 !important;
}
  `, "",{"version":3,"sources":["webpack://./src/shared/RadioButton/RadioButton.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".custom-radio {\n    background: #4E59E3;\n}\n\n.custom-radio:checked {\n    background: #4E59E3 !important;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
