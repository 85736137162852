export const offline_title = {
    1: "Unable to Load the response!",
    2: "अभी ये प्रतिक्रिया जारी करने में असमर्थ है",
    //More languages to be added
};

export const offline_desc = {
    1: "please check your internet connection and try again once the network is back",
    2: "कृपया अपने इंटरनेट कनेक्शन की जाँच करें और नेटवर्क वापस आने के बाद फिर कोशिश करें।",
    //More languages to be added
};

export const offline_btn = {
    1: "Try Again",
    2: "फिर कोशिश करें",
    //More to be added
}