import * as React from 'react';
import { getTextFrmPrompt } from "../../../js/helper";
import { NEWCHAT_PROMPT, BACK_PROMPT, STARTNEWCHAT_PROMPT} from "../../../constants/NewChat-Constants";
import "./NewChatDialog.css";

const NewChatDialog = ({ selectedLanguageCode, handleResetChat = () => {}, handleSetNewChatClick = ()=> {}}) => {
  return (
    <div className='newChatDialog'>
        <div className='newChatBackground' />
        <div className='newChatDialogBox'>
            <p className='newChatTitle md:font-bold'>
                {getTextFrmPrompt(NEWCHAT_PROMPT, selectedLanguageCode)}
            </p>
            <div className='newChatAction'>
                <span className='newChatBtn closeBtn md:font-bold' onClick={handleSetNewChatClick}>
                    <img src="/Assets/Images/backIcn.svg" />
                    <span className='hidden md:inline'>{getTextFrmPrompt(BACK_PROMPT, selectedLanguageCode)}</span>
                </span>
                <span className='newChatBtn startBtn md:font-bold' onClick={handleResetChat}>
                    <span className='hidden md:inline'>{getTextFrmPrompt(STARTNEWCHAT_PROMPT, selectedLanguageCode)}</span>
                    <img src="/Assets/Images/arrowRightColor.svg" />
                </span>
            </div>
        </div>
    </div>
  )
}

export default NewChatDialog