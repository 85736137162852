import { ADD_TO_THE_LIST } from "../constants/Constants";

export const getTextFrmPrompt = (itm, val) =>
  typeof itm === "string" || itm instanceof String ? itm : itm[val];


export const formatUserResp = (val, langVal) => {
  if (val.length !== 0) {
    return {
      text: val.reduce((retString, itm) => {
        const raw_user_resp =
          itm.value === ADD_TO_THE_LIST && itm.raw_user_resp
            ? ` - (${itm.raw_user_resp})`
            : "";
        return (retString += `${(retString ? ", " : "") +
          getTextFrmPrompt(itm.text || itm.prompt.text, langVal)
          }${raw_user_resp}`);
      }, ""),
      image: [],
      type: "free-text",
    };
  }
};

export function generateRandomEmail() {
  const emailDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'example.com'];
  const randomDomainIndex = Math.floor(Math.random() * emailDomains.length);
  const randomUsername = Math.random().toString(36).substring(7);
  const randomDomain = emailDomains[randomDomainIndex];
  return `${randomUsername}@${randomDomain}`;
}

export function getProcesssedChatArr(raw_chat_data, langOpt) {
  let chatArr = []
  const respArr = raw_chat_data.history?.responses;
  const quesArr = raw_chat_data.history?.questions;
  for (let index = 0; index < respArr?.length; index++) {
    const respItm = respArr[index];
    const quesItm = quesArr[index];
    if (quesItm["qid"] !== "100") {
      if (respItm.values.length !== 0) {
        chatArr.push({
          qid: respItm.qid,
          src: "user",
          prompt: formatUserResp(respItm.values, langOpt),
          type: quesItm.type,
        });
      }
      if (quesItm?.prompt?.text) {
        chatArr.push({
          qid: quesItm.qid,
          src: "bot",
          prompt: quesItm.prompt,
          type: quesItm.type,
        });
      }
    } else {
      if (quesItm?.prompt?.text) {
        chatArr.push({
          qid: quesItm.qid,
          src: "bot",
          prompt: quesItm.prompt,
          type: quesItm.type,
        });
      }
      if (respItm.values.length !== 0) {
        chatArr.push({
          qid: respItm.qid,
          src: "user",
          prompt: formatUserResp(respItm.values, langOpt),
          type: quesItm.type,
        });
      }
    }
  }
  return chatArr;
};

export function getProcesssedCurrQues(currQues) {
  return {
    "prompt": currQues['prompt'],
    "q_id": currQues['qid'],
    "src": "bot",
    "type": currQues['type'],
    "responses": currQues['responses']
  }
};


export function getProcessResponse(q_id, response) {
  return {
    "prompt": response['prompt'],
    "src": "user",
    "q_id": q_id + "_a"
  }
}

export function getCurrentQuesID(chatArray) {
  return chatArray[chatArray.length - 1]["q_id"]
}

export function getProcessedCustomPayload(inputText, selectedLanguageCode) {
  return {
          "text": inputText,
          "lang": selectedLanguageCode
    }
  }

export function getProcessedCustomResponse(q_id, inputText) {
  return {
    "prompt": {
      "text": {
        "1": inputText,
        "2": inputText
      }
    },
    "src": "user",
    "q_id": q_id + "_a"
    }
}

export function getProcesssedModelQuestion(q_id, modelPrompt) {
  return {
    "prompt": {
      "text": modelPrompt["output"]["bot"]
    },
    "q_id": q_id,
    "src": "bot",
    "type": "",
    "responses": [],
    "sources": modelPrompt["sources"]
  }
}

export function getCurrentDateTime() {
  return new Date();
}