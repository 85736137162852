export const ADD_TO_THE_LIST = "ADD_TO_THE_LIST";
export const AUTOCOMPLETE_SYMP_QUES_VAL = "112";
export const NAME_PLACE_HOLDER = {
  1: "Anita",
  2: "अनीता",
};

export const CUSTOM_ANSWER_STRING = "I want to ask my own question"

export const REFERENCES_PROMPT = {
  1: "Reference",
  2: "संदर्भ",
  3: "மீட்டமை",
  4: "రీసెట్ చేయండి",
  5: "പുനഃസജ്ജമാക്കുക",
  6: "ಮರುಹೊಂದಿಸಿ",
  7: "रीसेट करा",
  8: "ପୁନ et ସେଟ୍ କରନ୍ତୁ |",
  9: "ਰੀਸੈਟ ਕਰੋ",
  10: "રીસેટ કરો",
  11: "ৰিছেট কৰক",
  12: "রিসেট",
  13: "دوبارہ ترتیب دیں۔",
};

export const REFERENCES_MSG = {
  1: "This response is gathered from ASHA Training modules.",
  2: "यह प्रतिक्रिया आशा प्रशिक्षण मॉड्यूल से एकत्र की गई है।"
  //To be added more later
};

export const SOURCES_PROMPT = {
  1: "View answer sources",
  2: "उत्तर स्रोत देखें",
  //To be added more later
}

export const PAGES_PROMPT = {
  1: "Page",
  2: "पृष्ठ",
  3: "மீட்டமை",
  4: "రీసెట్ చేయండి",
  5: "പുനഃസജ്ജമാക്കുക",
  6: "ಮರುಹೊಂದಿಸಿ",
  7: "रीसेट करा",
  8: "ପୁନ et ସେଟ୍ କରନ୍ତୁ |",
  9: "ਰੀਸੈਟ ਕਰੋ",
  10: "રીસેટ કરો",
  11: "ৰিছেট কৰক",
  12: "রিসেট",
  13: "دوبارہ ترتیب دیں۔",
};

export const FEEDBACK_PROMPT = {
  1: "Send Feedback",
  2: "फ़ीडबैक भेजें",
  3: "மீட்டமை",
  4: "రీసెట్ చేయండి",
  5: "പുനഃസജ്ജമാക്കുക",
  6: "ಮರುಹೊಂದಿಸಿ",
  7: "रीसेट करा",
  8: "ପୁନ et ସେଟ୍ କରନ୍ତୁ |",
  9: "ਰੀਸੈਟ ਕਰੋ",
  10: "રીસેટ કરો",
  11: "ৰিছেট কৰক",
  12: "রিসেট",
  13: "دوبارہ ترتیب دیں۔",
};

export const CANCEL_PROMPT = {
  1: "Cancel",
  2: "रद्द करें",
  3: "ரத்து செய்",
  4: "రద్దు చేయండి",
  5: "റദ്ദാക്കുക",
  6: "ರದ್ದುಮಾಡಿ",
  7: "रद्द करा",
  8: "ବାତିଲ୍ କରନ୍ତୁ |",
  9: "ਰੱਦ ਕਰੋ",
  10: "રદ કરો",
  11: "বাতিল কৰক",
  12: "বাতিল",
  13: "منسوخ کریں۔",
};

export const FEEDBACK_DIALOG_MSSG = {
  1: "Select all that are applicable.",
  2: "आप एक से ज़्यादा भी चुन सकते हैं।",
  3: "இதுவரை கேள்விகளுக்கு வழங்கப்பட்ட அனைத்து பதில்களையும் நீக்க விரும்புகிறீர்களா?",
  4: "మీరు ఖచ్చితంగా, ఇప్పటివరకు ప్రశ్నలకు ఇచ్చిన అన్ని ప్రతిస్పందనలను తొలగించాలనుకుంటున్నారా?",
  5: "ഇതുവരെയുള്ള ചോദ്യങ്ങൾക്ക് നൽകിയ എല്ലാ പ്രതികരണങ്ങളും ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  6: "ನೀವು ಖಚಿತವಾಗಿ, ಇಲ್ಲಿಯವರೆಗೆ ಪ್ರಶ್ನೆಗಳಿಗೆ ನೀಡಿದ ಎಲ್ಲಾ ಪ್ರತಿಕ್ರಿಯೆಗಳನ್ನು ಅಳಿಸಲು ಬಯಸುವಿರಾ?",
  7: "तुमची खात्री आहे की, तुम्ही आतापर्यंत प्रश्नांना दिलेले सर्व प्रतिसाद हटवू इच्छिता?",
  8: "ଆପଣ ନିଶ୍ଚିତ କି, ଆପଣ ଏପର୍ଯ୍ୟନ୍ତ ପ୍ରଶ୍ନଗୁଡିକୁ ଦିଆଯାଇଥିବା ସମସ୍ତ ପ୍ରତିକ୍ରିୟା ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  9: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਹੋ, ਤੁਸੀਂ ਹੁਣ ਤੱਕ ਦੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  10: "શું તમને ખાતરી છે કે, તમે અત્યાર સુધીના પ્રશ્નોના આપેલા તમામ જવાબો કાઢી નાખવા માંગો છો?",
  11: "আপুনি নিশ্চিত নেকি, এতিয়ালৈকে প্ৰশ্নবোৰৰ সকলো সঁহাৰি আপুনি মচি পেলাব বিচাৰে?",
  12: "আপনি কি নিশ্চিত, আপনি এখন পর্যন্ত প্রশ্নগুলির দেওয়া সমস্ত প্রতিক্রিয়া মুছে ফেলতে চান?",
  13: "کیا آپ کو یقین ہے کہ آپ اب تک کے سوالات کے جوابات کو حذف کرنا چاہتے ہیں؟",
};


export const FEEDBACK_DIALOG_TITLE = {
  1: "Share your feedback.",
  2: "आपको जो भी सही लगे उसे चुनकर अपना अनुभव और फीडबैक साझा करें ।",
  3: "இதுவரை கேள்விகளுக்கு வழங்கப்பட்ட அனைத்து பதில்களையும் நீக்க விரும்புகிறீர்களா?",
  4: "మీరు ఖచ్చితంగా, ఇప్పటివరకు ప్రశ్నలకు ఇచ్చిన అన్ని ప్రతిస్పందనలను తొలగించాలనుకుంటున్నారా?",
  5: "ഇതുവരെയുള്ള ചോദ്യങ്ങൾക്ക് നൽകിയ എല്ലാ പ്രതികരണങ്ങളും ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?",
  6: "ನೀವು ಖಚಿತವಾಗಿ, ಇಲ್ಲಿಯವರೆಗೆ ಪ್ರಶ್ನೆಗಳಿಗೆ ನೀಡಿದ ಎಲ್ಲಾ ಪ್ರತಿಕ್ರಿಯೆಗಳನ್ನು ಅಳಿಸಲು ಬಯಸುವಿರಾ?",
  7: "तुमची खात्री आहे की, तुम्ही आतापर्यंत प्रश्नांना दिलेले सर्व प्रतिसाद हटवू इच्छिता?",
  8: "ଆପଣ ନିଶ୍ଚିତ କି, ଆପଣ ଏପର୍ଯ୍ୟନ୍ତ ପ୍ରଶ୍ନଗୁଡିକୁ ଦିଆଯାଇଥିବା ସମସ୍ତ ପ୍ରତିକ୍ରିୟା ବିଲୋପ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
  9: "ਕੀ ਤੁਸੀਂ ਯਕੀਨੀ ਹੋ, ਤੁਸੀਂ ਹੁਣ ਤੱਕ ਦੇ ਸਵਾਲਾਂ ਦੇ ਜਵਾਬਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?",
  10: "શું તમને ખાતરી છે કે, તમે અત્યાર સુધીના પ્રશ્નોના આપેલા તમામ જવાબો કાઢી નાખવા માંગો છો?",
  11: "আপুনি নিশ্চিত নেকি, এতিয়ালৈকে প্ৰশ্নবোৰৰ সকলো সঁহাৰি আপুনি মচি পেলাব বিচাৰে?",
  12: "আপনি কি নিশ্চিত, আপনি এখন পর্যন্ত প্রশ্নগুলির দেওয়া সমস্ত প্রতিক্রিয়া মুছে ফেলতে চান?",
  13: "کیا آپ کو یقین ہے کہ آپ اب تک کے سوالات کے جوابات کو حذف کرنا چاہتے ہیں؟",
};

export const feedbackResponses = [
  {
    value: "Overall Experience Needs Improvement",
    prompt: {
      text: {
        1: "Overall Experience Needs Improvement",
        2: "कुल मिलाकर अनुभव में सुधार की जरूरत है",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answer seems unreliable",
    prompt: {
      text: {
        1: "Answer seems unreliable",
        2: "जवाब अविश्वसनीय लगता है",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answer difficult to understand",
    prompt: {
      text: {
        1: "Answer difficult to understand",
        2: "जवाब समझने में मुश्किल है",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answers seems confusing",
    prompt: {
      text: {
        1: "Answers seems confusing",
        2: "जवाब भ्रामक लगता है",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answers seems contradictory",
    prompt: {
      text: {
        1: "Answers seems contradictory",
        2: "जवाब विरोधाभासी लगता है",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Missed Important Details in Transcription",
    prompt: {
      text: {
        1: "Missed Important Details in Transcription",
        2: "प्रतिलेखन में महत्वपूर्ण विवरण छूट गए",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answer Retrieval took too long",
    prompt: {
      text: {
        1: "Answer Retrieval took too long",
        2: "उत्तर पुनर्प्राप्ति में लंबा समय लगा",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answer narration needs improvement",
    prompt: {
      text: {
        1: "Answer narration needs improvement",
        2: "उत्तर में सुधार की आवश्यकता है",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  {
    value: "Answer has grammatical errors",
    prompt: {
      text: {
        1: "Answer has grammatical errors",
        2: "उत्तर में व्याकरण की त्रुटियाँ हैं",
        3: "சேமி & அடுத்து",
        4: "సేవ్ & తదుపరి",
        5: "സേവ് & അടുത്തത്",
        6: "ಉಳಿಸಿ ಮತ್ತು ಮುಂದೆ",
        7: "जतन करा आणि पुढील",
        8: "ସେଭ୍ ଏବଂ ପରବର୍ତ୍ତୀ",
        9: "ਸੰਭਾਲੋ ਅਤੇ ਅੱਗੇ",
        10: "સાચવો અને આગળ",
        11: "সংৰক্ষণ আৰু পৰৱৰ্তী",
        12: "সংরক্ষণ করুন এবং পরবর্তী",
        13: "محفوظ کریں اور اگلا",
      },
    },
  },
  // {
  //   value: "ADD_TO_THE_LIST",
  //   prompt: {
  //     text: {
  //       1: "Give additional feedback, if any",
  //       2: "अपने अतिरिक्त फीडबैक साझा करें",
  //       3: "\u0baa\u0b9f\u0bcd\u0b9f\u0bbf\u0baf\u0bb2\u0bbf\u0bb2\u0bcd \u0b9a\u0bc7\u0bb0\u0bcd\u0b95\u0bcd\u0b95\u0bb5\u0bc1\u0bae\u0bcd",
  //       4: "\u0c1c\u0c3e\u0c2c\u0c3f\u0c24\u0c3e\u0c15\u0c41 \u0c1c\u0c4b\u0c21\u0c3f\u0c02\u0c1a\u0c02\u0c21\u0c3f",
  //       5: "\u0d2a\u0d1f\u0d4d\u0d1f\u0d3f\u0d15\u0d2f\u0d3f\u0d7d \u0d1a\u0d47\u0d7c\u0d15\u0d4d\u0d15\u0d41\u0d15",
  //       6: "\u0caa\u0c9f\u0ccd\u0c9f\u0cbf\u0c97\u0cc6 \u0cb8\u0cc7\u0cb0\u0cbf\u0cb8\u0cbf",
  //       7: "\u092f\u093e\u0926\u0940\u0924 \u091c\u094b\u0921\u093e",
  //       8: "\u0b24\u0b3e\u0b32\u0b3f\u0b15\u0b3e\u0b30\u0b47 \u0b2f\u0b4b\u0b21\u0b28\u0b4d\u0b24\u0b41",
  //       9: "\u0a38\u0a42\u0a1a\u0a40 \u0a35\u0a3f\u0a71\u0a1a \u0a38\u0a3c\u0a3e\u0a2e\u0a32 \u0a15\u0a30\u0a4b",
  //       10: "\u0aaf\u0abe\u0aa6\u0ac0\u0aae\u0abe\u0a82 \u0a89\u0aae\u0ac7\u0ab0\u0acb",
  //       11: "\u09a4\u09be\u09b2\u09bf\u0995\u09be\u09a4 \u09af\u09cb\u0997 \u0995\u09f0\u0995",
  //       12: "\u09a4\u09be\u09b2\u09bf\u0995\u09be\u09af\u09bc \u09af\u09cb\u0997 \u0995\u09b0\u09c1\u09a8",
  //       13: "\u0641\u06c1\u0631\u0633\u062a \u0645\u06cc\u06ba \u0634\u0627\u0645\u0644 \u06a9\u0631\u06cc\u06ba\u06d4",
  //     },
  //   },
  //   raw_user_resp: "",
  // },
];