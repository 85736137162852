import './App.css';
import * as React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Introduction from './core/Components/Introduction/Introduction';
import Language from './core/Components/Language/Language';
import Consent from './core/Components/Consent/Consent';
import { langaugeCode } from './constants/Language-Constants';
import ChatBody from './core/Components/ChatBody/ChatBody';
import { APP_URL_TOKEN } from './env';
import OfflineScreen from './core/Components/OfflineScreen/OfflineScreen';



function App() {
  // const token = new URLSearchParams(window.location.search).get('token');
  const token = APP_URL_TOKEN;
  const [selectedLanguage, setSelectedLangugage] = React.useState(langaugeCode["English"]);
  const [isOnline, setNetwork] = React.useState(window.navigator.onLine);
  const updateNetwork = () => {
    const online = window.navigator.onLine;
    setNetwork(online);
  };
  React.useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  }, []);

  const handleTryAgain = () => {
    return isOnline ? window.location.reload() : null;
  };

  return (
    <div className='h-screen w-screen overflow-hidden overflow-y-scroll'>
      <Router>
        <Routes>
          <Route exact path="/" element={isOnline ? <Introduction selectedLanguageCode={selectedLanguage} setSelectedLanguageCode={setSelectedLangugage} token={token} /> : <OfflineScreen selectedLanguageCode={selectedLanguage} handleTryAgain={handleTryAgain}/>}></Route>
          <Route path="/chat" element={isOnline ? <ChatBody selectedLanguageCode={selectedLanguage} token={token} setSelectedLanguageCode={setSelectedLangugage} /> : <OfflineScreen selectedLanguageCode={selectedLanguage} handleTryAgain={handleTryAgain}/>}></Route>
          <Route path="/language" element={isOnline ? <Language token={token} selectedLanguageCode={selectedLanguage} selectedLanguageFun={setSelectedLangugage} /> : <OfflineScreen selectedLanguageCode={selectedLanguage} handleTryAgain={handleTryAgain}/>}></Route>
          <Route path="/consent" element={isOnline ? <Consent token={token} selectedLanguageCode={selectedLanguage} setSelectedLanguageCode={setSelectedLangugage} /> : <OfflineScreen selectedLanguageCode={selectedLanguage} handleTryAgain={handleTryAgain}/>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
