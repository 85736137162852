import * as React from 'react';
import Header from '../Header/Header';
import ChatInputBar from '../../../shared/ChatInputBar/ChatInputBar';
import ChatInputMic from '../../../shared/ChatInputMic/ChatInputMic';
import { generateRandomEmail, getCurrentDateTime, getCurrentQuesID, getProcessResponse, getProcessedCustomPayload, getProcessedCustomResponse, getProcesssedAshaResp, getProcesssedChatArr, getProcesssedCurrQues, getProcesssedModelQuestion } from '../../../js/helper';
import ChatQueue from '../ChatQueue/ChatQueue';
import "./ChatBody.css";
import { getAshaModelResp, getChatHistory, getCurrentQuestion, handleChatReset, saveResponse } from '../../../js/global';
import { CUSTOM_RESPONSE_STRING, FREE_TEXT_RESPONSE } from '../../../constants/UserInput-Constants';
import { dummyQuestion } from '../../../constants/Question-Constants';
import NewChatDialog from '../NewChatDialog/NewChatDialog';
import { PROMPT_ERROR_MAP } from '../../../constants/Error-Constants';

const ChatBody = ({ selectedLanguageCode, token, setSelectedLanguageCode = () => { } }) => {
    const [inputText, setInputText] = React.useState('');
    const [appToken, setAppToken] = React.useState(null);
    const [chatArr, setChatArr] = React.useState([]);
    const [username, setUsername] = React.useState(null);
    const [currentBlock, setCurrentBlock] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [newChat, setNewChat] = React.useState(false);
    const [stopAudioFlag, setStopAudioFlag] = React.useState(false);

    function appendCurrentQuestion(token, chatArray, errorFlag = false) {
        getCurrentQuestion(token)
            .then((res) => {
                if (res) {
                    let currQues = getProcesssedCurrQues(res);
                    currQues["date_time"] = getCurrentDateTime();
                    if (chatArray.length > 0 && !errorFlag) {
                        chatArray.pop();
                        setChatArr(chatArray);
                    }
                    chatArray.push(currQues);
                    setChatArr(chatArray);
                    setCurrentBlock(currQues);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function appendErrorPrompt() {
        let temp = chatArr;
        temp[temp.length - 1]["prompt"]["text"] = PROMPT_ERROR_MAP;
        delete temp[temp.length - 1]["dummy"];
        setChatArr(temp);
    }

    function sendChipAnswer(q_id, option) {
        let tmp = chatArr;
        tmp[tmp.length - 1]["answer"] = option.value;
        setChatArr(tmp);
        let response = getProcessResponse(q_id, option);
        response["date_time"] = getCurrentDateTime();
        tmp.push(response);
        setCurrentBlock(response);
        setChatArr(tmp);
        setIsLoading(true);
        tmp.push(dummyQuestion);
        setCurrentBlock(dummyQuestion);
        setChatArr(tmp);
        saveResponse(appToken, q_id, option).then((res) => {
            if(res["result"]) {
                let currentQuestion = getProcesssedModelQuestion(q_id, res["result"]);
                tmp.pop();
                tmp.push(currentQuestion);
                tmp.push(dummyQuestion);
                setCurrentBlock(dummyQuestion);
                setChatArr(tmp);
                appendCurrentQuestion(appToken, chatArr)          
            }
            else if(res["error"]) {
                appendErrorPrompt();
                appendCurrentQuestion(appToken, chatArr, true);
            }
            else {
                appendCurrentQuestion(appToken, chatArr);
            }
        });
    }

    function sendCustomResponse(inputText) {
        const q_id = getCurrentQuesID(chatArr);
        const payload = getProcessedCustomPayload(inputText, selectedLanguageCode);
        const resp = getProcessedCustomResponse(q_id, inputText);
        let tmp = chatArr;
        tmp[tmp.length - 1]["answer"] = resp.value;
        resp["date_time"] = getCurrentDateTime();
        tmp.push(resp);
        setChatArr(tmp);
        setIsLoading(true);
        tmp.push(dummyQuestion);
        setCurrentBlock(dummyQuestion);
        setChatArr(tmp);
        saveResponse(appToken, q_id, payload).then((res) => {
            if(res["result"]) {
                let currentQuestion = getProcesssedModelQuestion(q_id, res["result"]);
                tmp.pop();
                tmp.push(currentQuestion);
                tmp.push(dummyQuestion);
                setCurrentBlock(dummyQuestion);
                setChatArr(tmp);
                appendCurrentQuestion(appToken, chatArr)
            }
            else if(res["error"]) {
                appendErrorPrompt();
                appendCurrentQuestion(appToken, chatArr, true);
            }
            else {
                appendCurrentQuestion(appToken, chatArr)
            }
        });
    }

    function handleNewChat(reset = false) {
        let payloadJSON = {
            "lang_id": "1",
            "q_set_id": "version-1",
            "ref_id": generateRandomEmail(),
            "srcType": null,
            "token": token
        }
        setUsername(payloadJSON["ref_id"].charAt(0).toUpperCase());
        reset ? appendCurrentQuestion(appToken, []) :
            getChatHistory(payloadJSON)
                .then((res) => {
                    if (res) {
                        if (res["Token"]) {
                            setAppToken(res["Token"]);
                        }
                        if (res['history']) {
                            let chatArray = getProcesssedChatArr(res, selectedLanguageCode);
                            setChatArr(chatArray);
                            appendCurrentQuestion(res["Token"], chatArray);
                        }
                        else {
                            setChatArr([]);
                            appendCurrentQuestion(res["Token"], [])
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
    }

    React.useEffect(() => {
        handleNewChat();
    }, []);

    function stopAudio() {
        setStopAudioFlag(!stopAudioFlag);
    }

    const handleSetNewChatClick = () => {
        setStopAudioFlag(!stopAudioFlag);
        setNewChat(!newChat);
    }

    function handleResetChat() {
        handleChatReset(appToken).then((res) => {
            setNewChat(false);
            setChatArr([]);
            setCurrentBlock(null);
            handleNewChat(true);
        });
    }



    return (
        <div className='h-full w-full flex flex-col items-center'>
            {newChat && <NewChatDialog selectedLanguageCode={selectedLanguageCode} handleResetChat={handleResetChat} handleSetNewChatClick={handleSetNewChatClick} />}
            <Header token={token} showNewButton={true} showLangButton={true} selectedLanguageCode={selectedLanguageCode} setSelectedLanguageCode={setSelectedLanguageCode} handleNewChat={handleSetNewChatClick} stopAudio={stopAudio} />
            <div className='relative mt-[5rem] h-[96vh] w-[100vw] flex flex-col bg-[#F2F3F9]'>
                <div className='overflow-hidden overflow-y-scroll flex-grow py-2 md:px-6 px-2'>
                    {chatArr.length > 0 && <ChatQueue username={username} chatData={chatArr} currentBlock={currentBlock} selectedLanguage={selectedLanguageCode} setAnswer={sendChipAnswer} token={appToken} isLoading={isLoading} stopAudioFlag={stopAudioFlag} />}
                </div>
                <div className={'sticky bottom-0 w-full bg-[#dbe0ee] flex items-center justify-center py-4'}>
                    <ChatInputBar input={inputText} selectedLanguageCode={selectedLanguageCode} disableFlag={isLoading} send={sendCustomResponse} />
                    <div className='sm:w-[1rem] w-[0.5rem]'></div>
                    <ChatInputMic selectedLanguageCode={selectedLanguageCode} disableFlag={isLoading} send={setInputText} />
                </div>
            </div>
        </div>
    );
}

export default ChatBody;
