import * as React from 'react';
import TextBox from '../../../shared/TextBox/TextBox';
import { getTextFrmPrompt } from '../../../js/helper';
import { NAME_PLACE_HOLDER } from '../../../constants/Constants';
import "./ChatQueueBlock.css";
import Feedback from '../Feedback/Feedback';
import UserInput from '../../../shared/UserInput/UserInput';
import { generateSpeech } from '../../../js/global';
import { languageName } from '../../../constants/Language-Constants';
import Loader from '../Loader/Loader';
import Sources from '../Sources/Sources';
import { PROMPT_ERROR_MAP } from '../../../constants/Error-Constants';
import { WelcomePrompt } from '../../../shared/WelcomePrompt/WelcomePrompt';
import { WelcomeMessageAudio, WelcomeMessageText } from '../../../constants/Message-Constants';
import moment from 'moment';

const ChatQueueBlock = ({ idx, showIcon, username, selectedLanguageCode, item, speakerEnabled, micStatus, activeState, lastQuesFlag, playAudio = () => { }, stopAudio = () => { }, setSpeakerEnabled = () => { }, setAnswer = () => { }, token, isLoading }) => {
  const isCollapsedRef = React.useRef(null);
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const getDisplayPrompt = () => {
    const propmtTxt = getTextFrmPrompt(item?.prompt?.text, selectedLanguageCode);
    const filtrPropmtTxt = propmtTxt.replace(
      NAME_PLACE_HOLDER[selectedLanguageCode],
      username
    );
    let errorFlag = filtrPropmtTxt == PROMPT_ERROR_MAP[selectedLanguageCode];
    return [filtrPropmtTxt, errorFlag];
  };

  const scrollCollapseable = () => {
    const chatAreaDiv = document.getElementById("chatQueue");
    if (isCollapsedRef.current?.offsetTop !== chatAreaDiv.offsetTop) {
      isCollapsedRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  React.useEffect(scrollCollapseable, [isCollapsed]);

  const displaySources = () => {
    return item?.sources?.length === 0 ? (
      ""
    ) : (
      <div ref={isCollapsedRef}>
        <Sources item={item} isCollapsed={isCollapsed} selectedLanguageCode={selectedLanguageCode} toggleCollapse={toggleCollapse} />
      </div>
    );
  };

  const initSpeechSynth = async () => {
    if (!micStatus) {
      setSpeakerEnabled(idx);
      const url = await generateSpeech(item?.prompt?.text[selectedLanguageCode], languageName[selectedLanguageCode]);
      playAudio(url);
    }
  };

  return (
    <div className='chatOuter'>
      {showIcon && item.src === "bot" ? (
        <div className={`bot_icon_div md:inline-block hidden relative`}>
          <img src={"/Assets/Images/bot_icon.png"} className="bot_icon" alt="Asha Avatar" />
          {getDisplayPrompt()[1] && <img src={"/Assets/Images/warningCircle.svg"} className='h-[2rem] absolute bottom-[16px] right-[-8px]' />}
          <div className='mt-2 time_stamp_div'>{moment(item.date_time).format('hh:mm A')}</div>
        </div>
      ) : showIcon && username ? (
        <div className="md:flex hidden user_icon_div">
          <div className='user_icon'>{username}</div>
          <div className='mt-2 time_stamp_div'>{moment(item.date_time).format('hh:mm A')}</div>
        </div>
      ) : null}
      <div
        className={`chatBlock 
        ${item.src === "bot"? (showIcon ? "ml-[1rem]" : "ml-[4rem]"): (showIcon ? "mr-[1rem]" : "mr-[4rem]")}
        ${item.src === "bot" ? "chatBlock_left" : "chatBlock_right"} 
        ${speakerEnabled === item?.q_id ? "chatBlock_highlight" : "chatBlock_normal"}
        ${getDisplayPrompt()[1] ? "chatErrorBlock_left" : ""}`}
      >
        {
          isLoading && item.src === "bot" && item?.dummy ?
            <Loader /> :
            (
              <div>
                <div className=''>
                  <div className={`${item.src === "bot" && !getDisplayPrompt()[1] ? "chatBlockInside_left" : item.src === "bot" ? "chatBlockInsideError_left" : "chatBlockInside_right"}`}>
                    {item.src == "bot" && getDisplayPrompt()[0].trim() == WelcomeMessageAudio[selectedLanguageCode].trim() ? <WelcomePrompt selectedLangaugeCode={selectedLanguageCode} /> : <TextBox text={getDisplayPrompt()[0]} />}
                    {
                      item.src === "bot" && !getDisplayPrompt()[1] && item.responses && <UserInput selectedLanguageCode={selectedLanguageCode} question_id={item.q_id} responseType={item.type} responses={item.responses} selectedAnswer={item.answer} speakerEnabled={speakerEnabled} lastQuesFlag={lastQuesFlag} setAnswer={setAnswer} activeState={activeState} />
                    }
                    {item.src === "bot" && !getDisplayPrompt()[1] && <Feedback selectedLangaugeCode={selectedLanguageCode} qid={item.q_id} sessionId={""} token={token} stopAudio={stopAudio} />}
                  </div>
                  {item?.sources?.length ? (
                    <div className="chatBlock_Sources">{displaySources()}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
      </div>
      {item.src === "bot" && !item?.dummy &&
        (speakerEnabled === idx ? (
          <button className="speakButton speakButton_left" onClick={stopAudio}>
            <img src={"/Assets/Images/speakerActive.png"} className="speakBtn_icn" alt="Speaker" />
          </button>
        ) : (
          <button className="speakButton speakButton_left" onClick={initSpeechSynth}>
            <img src={"/Assets/Images/speakerInactive.png"} className="speakBtn_icn" alt="Speaker" />
          </button>
        ))}
      {item.src === "user" && !item?.dummy &&
        (speakerEnabled === idx ? (
          <button className="speakButton speakButton_right" onClick={stopAudio}>
            <img src={"/Assets/Images/speakerActive.png"} className="speakBtn_icn" alt="Speaker" />
          </button>
        ) : (
          <button className="speakButton speakButton_right" onClick={initSpeechSynth}>
            <img src={"/Assets/Images/speakerInactive.png"} className="speakBtn_icn" alt="Speaker" />
          </button>
        ))}
    </div>
  );
};

export default ChatQueueBlock;
