// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button:active {
    box-shadow: 2px 2px 10px lightgray;    
}

`, "",{"version":3,"sources":["webpack://./src/shared/ActionsButton/ActionButton.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC","sourcesContent":[".action-button:active {\n    box-shadow: 2px 2px 10px lightgray;    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
