export const introduction_page = {
    English: {
        title: "ASHA Health Assistant",
        description: "ASHA Health Assistant is an AI-driven chatbot, intended for in-service use, designed to assist ASHA healthcare workers to effectively carry out their daily activities by providing them easy access to relevant information.",
        notes: [
            {
                description: "It is important to note that all healthcare workers are advised to use their judgement before implementing any of the advice given here"
            }
        ],
        action_button: {
            label: "Start Demo"
        }
    },
    हिंदी: {
        title: "आशा स्वास्थ्य सहायक",
        description: "आशा स्वास्थ्य सहायक एक ए.आई. द्वारा संचालित चैटबॉट है, जिसका उद्देश्य इन-सर्विस (जैसे, दैनिक स्वास्थ्य संबधी सेवाओं की जानकारी के लिये) उपयोग के लिए है, जिसे आशा कार्यकर्ताओं को आवश्यक जानकारी को आसानी से पहुचाने के लिये, और उनकी दैनिक गतिविधियों को प्रभावी ढंग से पूरा करने में सहायता करने के लिए डिज़ाइन किया गया है।",
        notes: [
            {
                description: "ये आवश्यक है कि सभी स्वास्थ्य कर्मी यहाँ दी गयी किसी भी सलाह को लागू करने से पहले अपनी सूझबूझ का इस्तेमाल करें ।"
            }
        ],
        action_button: {
            label: "डेमो शुरु करें"
        }
    }
}