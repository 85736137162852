// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: 2em; /* Adjust as needed */
    line-height: 1.5em; /* Adjust as needed */
  }

  .custom-checkbox-container input {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
  }

  .custom-checkbox-container .checkmark {
    position: absolute;
    left: 0;
    height: 1.25em; /* Adjust as needed */
    width: 1.25em; /* Adjust as needed */
    background-color: transparent;
    border: 1.75px solid #5F5C5C; /* Border color */
    border-radius: 0.15rem;
    cursor: pointer;
  }

  .custom-checkbox-container input:checked + .checkmark:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%) rotate(42.5deg);
    width: 0.45em; /* Size of the checkmark */
    height: 0.75em; /* Size of the checkmark */
    border: solid #4E59E3; /* Color of the checkmark */
    border-width: 0 3.25px 3.25px 0;
    border-radius: 0.125em; /* Adjust as needed */
  }
  .custom-checkbox-container input:checked ~ .checkmark {
    border-color: #4E59E3; /* Change border color when checked */
    border-radius: 0.175rem;
  }`, "",{"version":3,"sources":["webpack://./src/core/Components/Consent/Consent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,iBAAiB,EAAE,qBAAqB;IACxC,kBAAkB,EAAE,qBAAqB;EAC3C;;EAEA;IACE,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,OAAO;IACP,cAAc,EAAE,qBAAqB;IACrC,aAAa,EAAE,qBAAqB;IACpC,6BAA6B;IAC7B,4BAA4B,EAAE,iBAAiB;IAC/C,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gDAAgD;IAChD,aAAa,EAAE,0BAA0B;IACzC,cAAc,EAAE,0BAA0B;IAC1C,qBAAqB,EAAE,2BAA2B;IAClD,+BAA+B;IAC/B,sBAAsB,EAAE,qBAAqB;EAC/C;EACA;IACE,qBAAqB,EAAE,qCAAqC;IAC5D,uBAAuB;EACzB","sourcesContent":[".custom-checkbox-container {\n    position: relative;\n    display: inline-flex;\n    align-items: center;\n    padding-left: 2em; /* Adjust as needed */\n    line-height: 1.5em; /* Adjust as needed */\n  }\n\n  .custom-checkbox-container input {\n    position: absolute;\n    left: 0;\n    top: 0;\n    display: none;\n  }\n\n  .custom-checkbox-container .checkmark {\n    position: absolute;\n    left: 0;\n    height: 1.25em; /* Adjust as needed */\n    width: 1.25em; /* Adjust as needed */\n    background-color: transparent;\n    border: 1.75px solid #5F5C5C; /* Border color */\n    border-radius: 0.15rem;\n    cursor: pointer;\n  }\n\n  .custom-checkbox-container input:checked + .checkmark:after {\n    content: \"\";\n    position: absolute;\n    left: 50%;\n    top: 40%;\n    transform: translate(-50%, -50%) rotate(42.5deg);\n    width: 0.45em; /* Size of the checkmark */\n    height: 0.75em; /* Size of the checkmark */\n    border: solid #4E59E3; /* Color of the checkmark */\n    border-width: 0 3.25px 3.25px 0;\n    border-radius: 0.125em; /* Adjust as needed */\n  }\n  .custom-checkbox-container input:checked ~ .checkmark {\n    border-color: #4E59E3; /* Change border color when checked */\n    border-radius: 0.175rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
