import * as React from 'react';

const TextButton = ({ textLabel, activeFlag, languageCode, clickFun = () => { } }) => {
    return (
        <div className='flex items-center justify-center md:py-8 md:px-8 py-6 px-6 rounded-xl w-[15rem] cursor-pointer' style={{ background: activeFlag ? "#4E59E3" : "#D6DBF9", color: activeFlag ? "#FFFFFF" : "#4E59E3", fontWeight: activeFlag ? "700" : "", border: "1px solid #4E59E3", boxShadow: activeFlag ? "5px 5px 10px #D6DBF9" : "" }} onClick={() => clickFun(languageCode)}>
            <span className='md:text-4xl text-2xl'>{textLabel}</span>
        </div>
    )
}

export default TextButton;