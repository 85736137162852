import * as React from 'react';
import ActionButton from '../../../shared/ActionsButton/ActionButton';
import { useNavigate } from 'react-router-dom';
import { introduction_page } from '../../../constants/Introduction-Constants';
import { langaugeCode, languageLabel } from '../../../constants/Language-Constants';
import { newChat, exit } from '../../../constants/Header-Constants';

const Header = ({ selectedLanguageCode, token, showLangButton, showNewButton, showExitButton = true, offline = false, setSelectedLanguageCode = () => { }, handleNewChat = () => {}, stopAudio = () => { } }) => {
    const [selectedLanguage, setSelectedLangugage] = React.useState(selectedLanguageCode);
    const [languageButtonLabel, setLanguageButtonLabel] = React.useState(getLanguageLabel(languageLabel[selectedLanguageCode]));
    let navigate = useNavigate();
    
    const exitChat = () => {
        navigate(`/`)
    }

    function getLanguageLabel(language) {
        if(language == "English") {
            return "हिंदी";
        } else {
            return "English";
        }
    }
    const toggleLanguage = () => {
        stopAudio();
        if (languageLabel[selectedLanguage] == "English") {
            setLanguageButtonLabel("English");
            setSelectedLangugage(langaugeCode["हिंदी"]);
            setSelectedLanguageCode(langaugeCode["हिंदी"]);
        }
        else {
            setLanguageButtonLabel("हिंदी");
            setSelectedLangugage(langaugeCode["English"]);
            setSelectedLanguageCode(langaugeCode["English"]);
        }
    }
    
    return (
        <div className='fixed flex justify-between align-center md:px-4 md:py-2 px-1 py-1 max-h-fit w-screen bg-[#FFFFFF] h-[5rem] drop-shadow-[0_4px_4px_rgba(0,0,0,0.08)]' style={{ borderBottom: "1px solid lightgray", zIndex: 9996 }}>
            <div className='pl-4 w-[50%] lg:text-2xl md:text-lg text-md flex items-center font-bold pointer-events-none'>
                {introduction_page[languageLabel[selectedLanguageCode]].title}
            </div>
            {!offline ?
            <div className='w-[50%] flex items-center justify-end'>
                {showNewButton && <div className='mx-2'>
                    <ActionButton bgColor={"#F3F3F3"} fontColor={"#0B0B0B"} text={newChat[selectedLanguageCode]} icon={"/Assets/Images/newChatIcon.svg"} actionFun={handleNewChat} />
                </div>}
                {showLangButton && <div className='mx-2'>
                    <ActionButton bgColor={"#E1E4F9"} fontColor={"#4E59E3"} text={languageButtonLabel} icon={"/Assets/Images/langIcon.svg"} actionFun={toggleLanguage} />
                </div>}
                {showExitButton && <div className='mx-2'>
                    <ActionButton bgColor={"#FFEAEA"} fontColor={"#ED553B"} text={exit[selectedLanguageCode]} icon={"/Assets/Images/crossIcon.svg"} actionFun={exitChat}/>
                </div>}
            </div>
            : null}
        </div>
    )
}

export default Header;