import * as React from 'react';
import "./ActionButton.css";

const ActionButton = ({bgColor, fontColor, text, icon, iconSize, actionFun = () => { }}) => {
    return (
        <div className="action-button flex items-center justify-center lg:px-6  md:px-4 px-2 lg:py-4 md:py-4 py-2 rounded-xl w-fit cursor-pointer" style={{"background": bgColor, "color": fontColor}} onClick={actionFun}>
            <div className="flex items-center mr-1" >
                {/* {offline ?
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M6.121 14.9875C5.81494 15.2935 5.31872 15.2935 5.01266 14.9875C4.7066 14.6814 4.7066 14.1852 5.01266 13.8791L8.89183 9.99998L5.01266 6.12081C4.7066 5.81475 4.7066 5.31854 5.01266 5.01248C5.31872 4.70642 5.81494 4.70642 6.121 5.01248L10.0002 8.89165L13.8793 5.01248C14.1854 4.70642 14.6816 4.70642 14.9877 5.01248C15.2937 5.31854 15.2937 5.81476 14.9877 6.12081L11.1085 9.99998L14.9877 13.8791C15.2937 14.1852 15.2937 14.6814 14.9877 14.9875C14.6816 15.2935 14.1854 15.2935 13.8793 14.9875L10.0002 11.1083L6.121 14.9875Z" fill="#ED553B" stroke="#ED553B"/>
                </svg>
                :
                <img src={icon} className='md:h-[1.2rem] h-[1rem]'/>
                } */}
                <img src={icon} className='md:h-[1.2rem] h-[1rem]'/>
            </div>
            <div className='hidden md:block ml-1 text-lg sm:text-sm' style={{"fontWeight": 600}}>
                {text}
            </div>
        </div>
    )
}

export default ActionButton;