import * as React from 'react';
import "./Loader.css";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-16 w-20 mx-4">
      <div className="flex space-x-3">
        <div className="w-2 h-2 rounded-full bg-gray-700 animate-dot1" />
        <div className="w-2 h-2 rounded-full bg-gray-400 animate-dot2" />
        <div className="w-2 h-2 rounded-full bg-gray-400 animate-dot3" />
      </div>
    </div>
  );
};

export default Loader;