// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback_container{
    /* float: right; */
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0.75rem 0.75rem;
}

.like_icn{
    width: 25px;
    object-fit: contain;
    margin: 0 5px 0 0;
    cursor: pointer;
}


.dislike_icn{
    width: 25px;
    object-fit: contain;
    transform: rotate(180deg);
    cursor: pointer;
}

.dislike_sel_icn{
    width: 25px;
    object-fit: contain;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/core/Components/Feedback/Feedback.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":[".feedback_container{\n    /* float: right; */\n    display: flex;\n    justify-content: flex-end;\n    padding: 0rem 0.75rem 0.75rem;\n}\n\n.like_icn{\n    width: 25px;\n    object-fit: contain;\n    margin: 0 5px 0 0;\n    cursor: pointer;\n}\n\n\n.dislike_icn{\n    width: 25px;\n    object-fit: contain;\n    transform: rotate(180deg);\n    cursor: pointer;\n}\n\n.dislike_sel_icn{\n    width: 25px;\n    object-fit: contain;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
