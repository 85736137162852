export const dummyQuestion = {
    "prompt": {
        "text": {
            "1": "dummy",
            "2": ""
        }
    },
    "src": "bot",
    "q_id": "0",
    "dummy": true
};