export const NEWCHAT_PROMPT = {
    1: "Are you sure you want to begin a new chat? Doing so will erase your previous conversation.",
    2: "क्या आप वाकई एक नई बातचीत/चैट शुरू करना चाहते हैं? ऐसा करने से आपकी पिछली बातचीत/चैट मिट जाएगी।",
    // More Languages to be added.
};

export const BACK_PROMPT = {
    1: "Back To Old Chat",
    2: "पुरानी चैट पर लौटें",
    // More to be added
};

export const STARTNEWCHAT_PROMPT = {
    1: "Start New Chat",
    2: "नई चैट शुरू करें",
    // More to be added
}