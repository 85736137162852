import * as React from 'react';
import TextButton from '../../../shared/TextButton/TextButton';
import { useNavigate } from 'react-router-dom';
import { langaugeCode } from '../../../constants/Language-Constants';
import Header from '../Header/Header';

const Language = ({token, selectedLanguageCode, selectedLanguageFun = () => { }}) => {
    let navigate = useNavigate();

    function changeLanguage(language) {
        selectedLanguageFun(language);
        navigate(`/consent`)
    }

    return (
        <div>
        <Header token={token} selectedLanguageCode={selectedLanguageCode}/>
        <div className='flex flex-col items-center justify-center h-[100vh] bg-[#F2F3F9]'>
            <div className='md:text-3xl text-2xl text-center'>
                Select your preferred language / अपनी पसंदीदा भाषा चुनें
            </div>
            <div className='md:flex md:flex-row items-center justify-center mt-10 md:w-[30rem] flex-col'>
                <TextButton  textLabel={"English"} languageCode={langaugeCode["English"]} activeFlag={selectedLanguageCode == langaugeCode["English"]} clickFun={changeLanguage} />
                <div className='w-[3rem] h-[1rem]'></div>
                <TextButton  textLabel={"हिंदी"} languageCode={langaugeCode["हिंदी"]} activeFlag={selectedLanguageCode == langaugeCode["हिंदी"]} clickFun={changeLanguage} />
            </div>
        </div>
        </div>
    )
}

export default Language;