import * as React from 'react';
import { introduction_page } from '../../../constants/Introduction-Constants';
import { languageLabel } from '../../../constants/Language-Constants';
import { useNavigate } from 'react-router-dom';
import IconTextButton from '../../../shared/IconTextButton/IconTextButton';
import Header from '../Header/Header';
const Introduction = ({ selectedLanguageCode , setSelectedLanguageCode = () => {}, token}) => {
    let navigate = useNavigate();
    const openLanguagePage = () => {
        navigate(`/language`)
    }
    return (
        <div className='flex flex-column h-full w-full'>
            <Header token={token} showLangButton={true} selectedLanguageCode={selectedLanguageCode} setSelectedLanguageCode={setSelectedLanguageCode} showExitButton={false}/>
            <div className='relative mt-[5rem] flex items-center justify-center h-[95%] w-[100%]'>
                <div className='hidden lg:block mx-24 my-20 rounded-[28px] w-[0] lg:w-[40%]'>
                    <img src={"/Assets/Images/ashaImg.svg"} style={{ "height": "90vh" }} />
                </div>
                <div className='flex flex-col justify-start pt-5 lg:pt-0 lg:justify-center h-[100%] lg:w-[60%] w-[90%] pl-0 lg:pl-[2.5rem] pr-0 lg:pr-[7.5%]'>
                    <div className='text-2xl sm:text-6xl font-extrabold '>
                        {introduction_page[languageLabel[selectedLanguageCode]].title}
                    </div>
                    <div className='my-6 lg:text-[20px] text-[18px] font-[600] text-[#444444]'>
                        {introduction_page[languageLabel[selectedLanguageCode]].description}
                    </div>
                    {introduction_page[languageLabel[selectedLanguageCode]].notes.map((note, index) => {
                        return <div key={index} className='rounded-[20px] bg-[#f4f5fd] p-6'>
                            <div className='lg:w-[85%] w-[100%] text-[16px]'>{note.description}</div>
                        </div>
                    })}
                    <div className='mt-10'>
                        <IconTextButton icon={"/Assets/Images/arrowRight.svg"} text={introduction_page[languageLabel[selectedLanguageCode]].action_button.label} clicked={openLanguagePage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;