import * as React from 'react';
import { consentData } from '../../../constants/Consent-Constants';
import { languageLabel, languageName } from '../../../constants/Language-Constants';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import IconTextButton from '../../../shared/IconTextButton/IconTextButton';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import "./Consent.css";
import { generateSpeech } from '../../../js/global';

const Consent = ({ token, selectedLanguageCode, setSelectedLanguageCode = () => {} }) => {
    const [answerFlag, setAnswerFlag] = React.useState(true);
    const audioRef = React.useRef(null);
    const [speakerEnabled, setSpeakerEnabled] = React.useState(false);
    const [selectedLanguage, setSelectedLangugage] = React.useState(languageLabel[selectedLanguageCode]);
    let navigate = useNavigate();
    const handleValueChange = () => {
        setAnswerFlag(!answerFlag);
    };

    const handleinitSpeech = async () => {
        if (speakerEnabled) {
            setSpeakerEnabled(false);
            stopAudio();
        }
        else {
            const url = await generateSpeech(consentData[languageLabel[selectedLanguageCode]]["description"], languageName[selectedLanguageCode]);
            setSpeakerEnabled(true);
            playAudio(url);
        }  
    };

    const playAudio = ((url) => {
        const newAudio = new Audio(url);
        if (audioRef.current) {
        audioRef.current.pause();
        }

        newAudio.addEventListener("ended", () => {
            setSpeakerEnabled(false);
        });
        audioRef.current = newAudio;
        newAudio.play().catch((err) => {
            console.error("Error playing audio:", err);
        });
    })

    const stopAudio = (() => {
        if(audioRef.current) {
          setSpeakerEnabled(false);
          audioRef.current.pause();
        }
      });

    React.useEffect(() => {
        return () => {
            stopAudio();
        }
    },[]); 

    function saveConsent() {
        // Save consent api call
        navigate(`/chat`)
    }

    return (
        <div className='h-full w-full flex flex-col'>
            <Header token={token} selectedLanguageCode={selectedLanguageCode} showLangButton={true} setSelectedLanguageCode={setSelectedLanguageCode} stopAudio={stopAudio} />
            <div className='mt-[5rem] h-[90vh] w-full flex items-center justify-center bg-[#F2F3F9]'>
                <div className='flex flex-col items-start md:px-0 px-4 w-[90vw] md:w-[60vw] md:mt-[2rem]'>
                    <div className='flex md:text-[32px] text-[24px] gap-5 items-center'>
                        {consentData[languageLabel[selectedLanguageCode]].title}
                        <div className={`flex border border-[#4E59E3] rounded-2xl text-[14px] font-[400] items-center px-6 py-1 gap-2 cursor-pointer ${speakerEnabled ? 'bg-[#4E59E3] text-[#FFFFFF]' : 'text-[#4E59E3]'}`} onClick={handleinitSpeech}>
                            <img src={speakerEnabled ? "/Assets/Images/activeListen.svg" : "/Assets/Images/listenButton.svg"} className='max-w-[1.75rem] max-h-[1.5rem]'/>
                            {consentData[languageLabel[selectedLanguageCode]]["listen_label"]}
                        </div>
                    </div>
                    <div className='md:text-[20px] sm:text-[16px] text-[14px] sm:mt-10 mt-8'>
                        {consentData[languageLabel[selectedLanguageCode]].description1}
                        <br/><br/>
                        {consentData[languageLabel[selectedLanguageCode]].description2}
                    </div>
                    <div className="flex mt-4">
                        {/* <div className="flex items-center mr-4">
                            <RadioButton id={"option_1"} color={"#4E59E3"} value={consentData[languageLabel[selectedLanguageCode]]["option_1"]["value"]} selectedValue={answerFlag} clickFun={handleValueChange} />
                            <label htmlFor="option_1" className="ml-2 md:text-[20px] text-[14px] font-medium text-[#5F5C5C]">{consentData[languageLabel[selectedLanguageCode]]["option_1"]["label"]}</label>
                        </div>
                        <div className="flex items-center mr-4">
                            <RadioButton color={"#4E59E3"} value={consentData[languageLabel[selectedLanguageCode]]["option_2"]["value"]} selectedValue={answerFlag} clickFun={handleValueChange} />
                            <label htmlFor="option_2" className="ml-2 md:text-[20px] text-[14px] font-medium text-[#5F5C5C]">{consentData[languageLabel[selectedLanguageCode]]["option_2"]["label"]}</label>
                        </div> */}
                        <div className='flex border border-[rgba(78, 89, 227, 0.14)] rounded-lg md:w-[60vw] w-[80vw] items-center px-4 py-3 bg-[#F9FCFF]'>
                            <div className='custom-checkbox-container '>
                                <input type="checkbox" id="myCheckbox" value={answerFlag}/>
                                <label htmlFor="myCheckbox" className="checkmark" onClick={handleValueChange}></label>
                            </div>
                            <label className='sm:text-[16px] text-[14px] font-normal'>{consentData[languageLabel[selectedLanguageCode]].check_label}</label>
                        </div>
                    </div>
                    <div className='lg:mt-10 md:mt-8 sm:mt-5 mt-4'>
                        <IconTextButton icon={answerFlag ? "/Assets/Images/ArrowRightDisabled.svg" :"/Assets/Images/arrowRight.svg"} text={consentData[languageLabel[selectedLanguageCode]].button_label} clicked={saveConsent} disabled={answerFlag} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consent;