import * as React from 'react';

const IconTextButton = ({text, icon, clicked = () => { }, disabled=false}) => {
    return (
        <div className={disabled ? `py-6 flex items-center justify-center lg:w-[16rem] md:w-[12rem] w-[12rem] rounded-2xl sm:text-[24px] text-[18px] font-extrabold bg-[#B7B7B7] text-[#868686] pointer-events-none` :`bg-[#4E59E3] text-[#FFFFFF] py-6 flex items-center justify-center lg:w-[16rem] md:w-[12rem] w-[12rem] rounded-2xl sm:text-[24px] text-[18px] font-extrabold cursor-pointer hover:shadow-[0px_0px_15px_0px_rgba(172,173,173,0.56)]`} onClick={clicked}>
                    {text}
                    <img className='ml-3' src={icon} />
        </div>
    )
}

export default IconTextButton;

