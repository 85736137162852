// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-input::-webkit-scrollbar {
    display: none;
}

.chat-input {
    -ms-overflow-style: none;
    scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/shared/ChatInputBar/ChatInputBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;AACzB","sourcesContent":[".chat-input::-webkit-scrollbar {\n    display: none;\n}\n\n.chat-input {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
