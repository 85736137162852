import AWS from './aws-config';

const Polly = new AWS.Polly();

export async function convertTextToSpeech(
  text,
  langCode
) {
  const params = {
    Text: text,
    OutputFormat: "mp3",
    VoiceId: process.env.REACT_APP_AWS_VOICE_ID,
    LanguageCode: langCode + "-IN",
  };
  let audioUrl = null;
  
  try {
    const data = await Polly.synthesizeSpeech(params).promise();
    const arrayBuffer = await new Response(data.AudioStream).arrayBuffer();
    let audioStream = new Uint8Array(arrayBuffer);
    
    if (audioStream) {
      const audioBlob = new Blob([audioStream], { type: "audio/mpeg" });
      audioUrl = window.URL.createObjectURL(audioBlob);
    }
  } catch (err) {
    console.log("Error synthesizing speech:", err);
  }

  return audioUrl;
}
