export const welcomeMessageText = {
  "1": `
  <div className='flex'>
    Namaste 
    <img src="/Assets/Images/namasteIcon.svg" alt="Namaste Icon" /> !
  </div>
  <div>
    I'm here to assist you in accessing prevalidated information you need to perform your health duties effectively.
    You can SPEAK 
    <img src="/Assets/Images/micIcon.svg" alt="Mic Icon" /> 
    or TYPE 
    <img src="/Assets/Images/textIcon.svg" alt="Text Icon" /> 
    your questions and READ 
    <img src="/Assets/Images/eyeIcon.svg" alt="Eye Icon" /> 
    or HEAR 
    <img src="/Assets/Images/speakerIcon.svg" alt="Speaker Icon" /> 
    my responses.
  </div>
  <br>
  <div>
    Can I assist you in finding answers for any of these tasks related to ASHA?
  </div>
`,
  "2": `
  <div className='flex'>
    नमस्ते 
    <img src="/Assets/Images/namasteIcon.svg" alt="Namaste Icon" /> !
  </div>
  <div>
    मैं यहां आपके स्वास्थ्य सम्बन्धी कार्यों को प्रभावी ढंग से पूरा करने के लिए पहले से जाँची परखी गयी आवश्यक जानकारी को आप तक पहुंचाने में आपकी सहायता करने के लिए हूँ । आप अपने प्रश्न बोल 
    <img src="/Assets/Images/micIcon.svg" alt="Mic Icon" /> 
    सकते हैं या टाइप 
    <img src="/Assets/Images/textIcon.svg" alt="Text Icon" /> 
    कर सकते हैं और मेरी प्रतिक्रियाओं को भी पढ़ 
    <img src="/Assets/Images/eyeIcon.svg" alt="Eye Icon" /> 
    या सुन 
    <img src="/Assets/Images/speakerIcon.svg" alt="Speaker Icon" />
     सकते हैं। 
  </div>
  <br>
  <div>
    क्या मैं आशा से संबंधित इनमें से किसी भी कार्य का उत्तर खोजने में आपकी सहायता कर सकती हूं?
  </div>
  `
}

export const WelcomeMessageAudio = {
  "1": "Namaste ! I'm here to assist you in accessing prevalidated information you need to perform your health duties effectively. You can SPEAK or TYPE your questions and READ or HEAR my responses Can I assist you in finding answers for any of these tasks related to ASHA ?",
  "2": "नमस्ते! मैं यहां आपके स्वास्थ्य सम्बन्धी कार्यों को प्रभावी ढंग से पूरा करने के लिए पहले से जाँची परखी गयी आवश्यक जानकारी को आप तक पहुंचाने में आपकी सहायता करने के लिए हूँ । आप अपने प्रश्न बोल सकते हैं या टाइप कर सकते हैं और मेरी प्रतिक्रियाओं को भी पढ़ या सुन सकते हैं। क्या मैं आशा से संबंधित इनमें से किसी भी कार्य का उत्तर खोजने में आपकी सहायता कर सकती हूं ?"
}
