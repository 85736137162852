import * as React from 'react';
import { welcomeMessageText } from '../../constants/Message-Constants';
import "./WelcomePrompt.css";

export const WelcomePrompt = ({ selectedLangaugeCode }) => {
    const createMarkup = () => ({ __html: welcomeMessageText[selectedLangaugeCode] });
    return (
        <div id="messageContainer" dangerouslySetInnerHTML={createMarkup()}>
        </div>            
    )
}