import qs from "query-string";

const fetchApi = async(url, method = "GET", body = {}, headers = new Headers(), params = {}) => {
    const obj = { method, headers, body }
    
    if (method === 'DELETE') {
        url = qs.stringifyUrl({ url: url, query: body });
        delete obj.body
    }

    if (method === 'GET') {
        delete obj.body
    }

    if (JSON.stringify(params) !== JSON.stringify({})) {
        var data = params
        var newURLSearchParams = url + "?"
        for (var k in data) {
            newURLSearchParams = newURLSearchParams + encodeURIComponent(k) + "=" + encodeURIComponent(data[k]) + "&"
        }
        newURLSearchParams = newURLSearchParams.slice(0, -1);
        console.log(newURLSearchParams);
        try {
            const response = await fetch(newURLSearchParams, obj);
            const text = await response.text();
            return response.ok ? JSON.parse(text) : { error: text };
        } catch (e) {
            console.log("error", e);
            return { error: e.message };
        }
    }

    try {
        const response = await fetch(url, obj);
        const text = await response.text();
        return response.ok ? JSON.parse(text) : { error: text };
    } catch (e) {
        console.log("error", e);
        return { error: e.message };
    }
};

export default fetchApi;
