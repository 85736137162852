export const consentData = {
    English: {
        title: "Informed Consent",
        description1: "This is an AI driven chat-bot aimed to provide assistance and answers to the questions of ASHA Workers from their training modules. You can interact with this chat-bot by typing or speaking out your questions.",
        description2: "Do you agree to provide your consent/permission to collect, store, and process your voice recordings, searched keywords, questions and answers to improve functionality and your user experience while using it?",
        description: "This is an AI driven chat-bot aimed to provide assistance and answers to the questions of ASHA health workers from their training materials. You can interact with this chat-bot by typing or speaking out your questions. Do you agree to provide your consent/permission to collect, store, and process your voice recordings, searched keywords, questions and answers to improve functionally and user experience?",
        listen_label: "Listen",
        button_label : "Next",
        check_label: "I allow ASHA Health Assistant to collect my above mentioned data.",
    },
    हिंदी: {
        title: "सहमति फॉर्म",
        description1: "यह एक ए.आई. संचालित चैट-बॉट है जिसका उद्देश्य आशा कार्यकर्ताओं को उनकी प्रशिक्षण माॅड्यूल में सहायता और सवालों के जवाब प्रदान करना है। आप अपने प्रश्नों को टाइप करके या बोलकर इस चैट-बॉट के साथ बातचीत कर सकते हैं।",
        description2: `क्या आप इस "आशा स्वास्थ्य सहायक " की उपयोगिता और इसे उपयोग करने के आपके अनुभव को बेहतर बनाने के लिए अपनी आवाज़ की रिकॉर्डिंग, खोजे गए शब्द, प्रश्नों एवं उत्तरों को एकत्र, संग्रहीत और भविष्य में उन्हें इस्तेमाल करने के लिए अपनी सहमति प्रदान करते हैं?`,
        description: "यह एक ए.आई. संचालित चैट-बॉट है जिसका उद्देश्य आशा कार्यकर्ताओं को उनकी प्रशिक्षण माॅड्यूल में सहायता और सवालों के जवाब प्रदान करना है। आप अपने प्रश्नों को टाइप करके या बोलकर इस चैट-बॉट के साथ बातचीत कर सकते हैं। क्या आप कार्यात्मक और उपयोगकर्ता अनुभव को बेहतर बनाने के लिए अपनी वॉयस रिकॉर्डिंग, खोजे गए कीवर्ड, प्रश्न और उत्तर एकत्र करने, संग्रहीत करने और संसाधित करने के लिए अपनी सहमति/अनुमति प्रदान करने के लिए सहमत हैं?",
        listen_label: "सुनो",
        button_label : "आगे जाएं",
        check_label: "मैं आशा स्वास्थ्य सहायक को मेरे उपर्युक्त डेटा एकत्र करने की अनुमति देता हूं / देती हूँ।",
    }

}