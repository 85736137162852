import * as React from "react";

const TextBox = ({ text = "" }) => {
  const pRef = React.useRef();

  React.useEffect(() => {
    const convertString = (inputString) => {
      const stringWithBr = inputString
        .replace(/\n/g, "<br>")
        .replace(/\t/g, "&nbsp&nbsp&nbsp&nbsp");
      const stringWithSingleSpace = stringWithBr.replace(/\s+/g, " ");

      return stringWithSingleSpace;
    };
    pRef.current.innerHTML = convertString(text);
  }, [text]);
  return <p className="m-0 md:text-[16px] text-[14px]" ref={pRef}></p>;
};

export default TextBox;
