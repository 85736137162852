import * as React from 'react';
import { CUSTOM_ANSWER_STRING } from '../../constants/Constants';

const MultiSelectChip = ({ selectedLanguageCode, question_id, options, selectedOption, speakerEnabled, activeState, selectOption = () => { } }) => {
    const optionTooLong = options[0]['prompt']['text'][selectedLanguageCode].length > 20;
    return (
        <div id='option-div' 
        className={`${activeState ? `` : `pointer-events-none`} ${optionTooLong ? "grid grid-cols-1" : "flex flex-wrap"}`}
        >
            {
                options.map((option, idx) => {
                    return (
                        <div key={idx} className={`flex items-center justify-center py-3 px-2 border text-md rounded-xl mx-2 my-1 cursor-pointer hover:text-[#4e58e3] hover:border-[#4e58e3] text-center ${selectedOption.includes(option['value']) ? "text-[#4e58e3] border-[#4e58e3] text-center font-extrabold shadow-[0_0_15px_0_rgba(172,173,173,0.56)]" : "text-[#747474]"}}`} onClick={() => selectOption(option['value'])} style={{background: `${selectedOption.includes(option['value']) ? "#FFFFFF" : "#DDE2E7"}`}}>
                            {selectedOption.includes(option['value']) && <img src={"/Assets/Images/tick.svg"} className='m-2' />}
                            {option['prompt']['text'][selectedLanguageCode]}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default MultiSelectChip;