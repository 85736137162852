import * as React from 'react';
import { CUSTOM_ANSWER_STRING } from '../../constants/Constants';

const SingleSelectChip = ({ selectedLanguageCode, question_id, options, selectedOption, lastQuesFlag, speakerEnabled, selectOption = () => { }, activeState }) => {
    const optionTooLong = options[0]['prompt']['text'][selectedLanguageCode].length > 20;
    return (
        <div id='option-div' 
        className={`${activeState ? `` : `pointer-events-none`} ${optionTooLong ? "grid grid-cols-1" : "flex flex-wrap"}`}
        >
            {
                options.map((option, idx) => {
                    return (
                        <div key={idx} className={`flex items-center justify-start py-3 px-4 border text-md  rounded-xl 
                        mx-2 my-3 cursor-pointer hover:text-[#4e58e3] hover:border-[#4e58e3] text-center  ${optionTooLong ? "" : "w-[fit-content]"}
                        ${option['value'] == selectedOption ? speakerEnabled != question_id ? 
                        "text-[#4e58e3] border-[#4e58e3] text-center font-extrabold" : 
                        "text-[#4e58e3] border-[#4e58e3] text-center font-extrabold" : ""}}`} 
                        onClick={() => selectOption(question_id, option)}
                        style={{
                            backgroundColor: option['value'] != selectedOption && !lastQuesFlag ? "#dddee1" : "",
                            color: option['value'] != selectedOption && !lastQuesFlag ? "#a6a6a6" : "",
                          }}>
                            {option['value'] == selectedOption && 
                            // speakerEnabled != question_id && 
                            <img src={"/Assets/Images/tick.svg"} className='m-2' />}
                            {/* {option['value'] == selectedOption && speakerEnabled == question_id && <img src={"/Assets/Images/tick-white.svg"} className='m-2' />} */}
                            {option['prompt']['text'][selectedLanguageCode]}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SingleSelectChip;