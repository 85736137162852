import * as React from 'react';
import { SINGLE_SELECT_RESPONSE, MULTI_SELECT_RESPONSE } from '../../constants/UserInput-Constants';
import SingleSelectChip from '../SingleSelectChip/SingleSelectChip';
import MultiSelectChip from '../MultiSelectChip/MultiSelectChip';

const UserInput = ({selectedLanguageCode, question_id, responseType, responses, selectedAnswer, speakerEnabled, lastQuesFlag, activeState, setAnswer = () => { }}) => {
    const getUserInput = () => {
        if (responseType == SINGLE_SELECT_RESPONSE) {
            return (
                <SingleSelectChip selectedLanguageCode={selectedLanguageCode} question_id={question_id} options={responses} selectedOption={selectedAnswer} speakerEnabled={speakerEnabled} lastQuesFlag={lastQuesFlag} activeState={activeState} selectOption={setAnswer} />
            )
        }
        else if(responseType === MULTI_SELECT_RESPONSE) {
            return(
                <MultiSelectChip selectedLanguageCode={selectedLanguageCode} question_id={question_id} options={responses} selectedOption={selectedAnswer} speakerEnabled={speakerEnabled} selectOption={setAnswer} activeState={activeState}/>
            )
        }
    }

    return (
        <div id='options' className='my-4'>
            {getUserInput()}
        </div>
    )
}

export default UserInput;